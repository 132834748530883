"use client";
import { Button } from "@/components/ui/button";
import { XIcon } from "lucide-react";
import { useAccountInfo, useRoleAuth } from "@/lib/hooks";
import Link from "next/link";
import { useParams, usePathname } from "next/navigation";
import { Permission } from "@prisma/client";
import { useState } from "react";
import { setCookie, getCookie } from "@/lib/utils";

export default function UpgradeDialog() {
  const [open, setOpen] = useState(() => {
    return getCookie("upgradeDialog") !== "closed";
  });
  const canUpdate = useRoleAuth("billing", Permission.UPDATE);
  const { data: account } = useAccountInfo();
  const path = usePathname();
  const { orgId, botId } = useParams<{ orgId?: string; botId?: string }>();
  const isFreePlan = account?.Plan?.is_free;

  const freePlan = {
    conditions: [
      `/org/${orgId}/bot/${botId}/sources`,
      `/org/${orgId}/bot/${botId}`,
    ].includes(path),
    title: "Upgrade to Basic Plan - Only $29/month",
    description:
      "You have reached your chatbot limit and cannot create more chatbots, but you can still continue to enjoy our services forever with the free plan unless you reached the message limit.",
    text: "With the Basic plan, you get higher message limits, multiple chatbots, more integrations, and enhanced features. Upgrade now to take full advantage of Resinq!",
    buttons: (
      <>
        <Button variant="secondary" asChild>
          <Link href="/pricing#compare">Compare Plans</Link>
        </Button>
        <Button asChild>
          <Link href="/account/plans">Upgrade Now</Link>
        </Button>
      </>
    ),
  };

  if (!canUpdate || !freePlan.conditions || !isFreePlan || !open) return null;

  return (
    <div className="fixed z-[99999999999999] grid sm:max-w-sm overflow-hidden border bg-background shadow-lg rounded-lg right-4 sm:right-auto left-4 bottom-4">
      <div className="flex items-center justify-between border-b bg-[#f6f5f4] px-3 sm:px-6 py-3">
        <p className="text-[0.9rem] font-semibold leading-[normal]">
          {freePlan.title}
        </p>
        <Button
          onClick={() => {
            setOpen(false);
            setCookie("upgradeDialog", "closed");
          }}
          className="shrink-0 mr-[-0.7rem]"
          variant="link"
          size="icon-sm"
        >
          <XIcon className="size-3.5" />
        </Button>
      </div>
      <div>
        <div className="p-3 flex flex-col gap-4 sm:py-4 sm:px-6">
          <p className="text-sm text-gray-700 text-justify">
            {freePlan.description}
          </p>
          <div className="grid grid-cols-2 gap-4">{freePlan.buttons}</div>
        </div>
      </div>
    </div>
  );
}
