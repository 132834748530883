"use client";
import { Button } from "@/components/ui/button";
import { XIcon } from "lucide-react";
import { useAccountInfo, useLimits, useRoleAuth, useUsages } from "@/lib/hooks";
import Link from "next/link";
import { Permission } from "@prisma/client";
import { useState } from "react";
import { useParams, usePathname } from "next/navigation";
import { getCookie, setCookie } from "@/lib/utils";

export default function ExceededLimitDialog() {
  const [open, setOpen] = useState(() => {
    return getCookie("exceededDialog") !== "closed";
  });

  const { data: account } = useAccountInfo();
  const limits = useLimits(account?.id);
  const usages = useUsages(account?.id);

  const path = usePathname();
  const { orgId, botId } = useParams<{ orgId?: string; botId?: string }>();
  const canUpdate = useRoleAuth("billing", Permission.UPDATE);
  const canCreateMember = useRoleAuth("team", Permission.CREATE);

  const isFreePlan = !!account?.Plan?.is_free;
  const isPremium = !!account?.Plan?.name?.toLowerCase().includes("premium");

  const isMessagesExceeded =
    !!usages?.data?.usages?.messages &&
    !!limits?.data?.limits?.messages &&
    usages?.data?.usages.messages >= limits?.data?.limits.messages;

  const isSTTExceeded =
    !!usages?.data?.usages?.sttDuration &&
    !!limits?.data?.limits?.sttDuration &&
    usages?.data?.usages.sttDuration >= limits?.data?.limits.sttDuration;

  const isTTSExceeded =
    !!usages?.data?.usages?.ttsChars &&
    !!limits?.data?.limits?.ttsChars &&
    usages?.data?.usages.ttsChars >= limits?.data?.limits.ttsChars;

  const isMemberCountExceeded =
    !isPremium &&
    !!usages?.data?.usages?.memberCount &&
    !!limits?.data?.limits?.memberCount &&
    usages?.data?.usages.memberCount >= limits?.data?.limits.memberCount;

  const isOrgCountExceeded =
    !isPremium &&
    !!usages?.data?.usages?.orgCount &&
    !!limits?.data?.limits?.orgCount &&
    usages?.data?.usages.orgCount >= limits?.data?.limits.orgCount;

  const isBotCountExceeded =
    !isPremium &&
    !!usages?.data?.usages?.botCount &&
    !!limits?.data?.limits?.botCount &&
    usages?.data?.usages.botCount >= limits?.data?.limits.botCount;

  let title = "";
  let description = "";
  let text = "";
  let conditions = false;

  const botListPage = `/org/${orgId}/bot`;
  const chatPage = `/org/${orgId}/bot/${botId}/chat`;
  const dashboardPage = `/org/${orgId}/bot/${botId}`;
  const membersPage = `/account/members`;

  if (isMessagesExceeded) {
    title = "Message Limit Exceeded";
    description =
      "Your chatbot have exceeded the message limit and your chatbot cannot respond more messages.";
    text =
      "Upgrade now to continue your chatbot to respond messages and avoid interruptions.";
    conditions = [chatPage, dashboardPage].includes(path);
  } else if (isSTTExceeded) {
    title = "STT Minutes Limit Exceeded";
    description = "You have exceeded your STT minutes limit.";
    text =
      "Upgrade now to increase your STT minutes and continue using speech-to-text features.";
    conditions = [chatPage, dashboardPage].includes(path);
  } else if (isTTSExceeded) {
    title = "TTS Character Limit Exceeded";
    description = "You have exceeded your TTS character limit.";
    text =
      "Upgrade now to increase your TTS character limit and continue using text-to-speech features.";
    conditions = [chatPage, dashboardPage].includes(path);
  } else if (isBotCountExceeded) {
    title = "Chatbot Limit Reached";
    description = isFreePlan
      ? "You have reached your chatbot limit and cannot create more chatbots, but you can still continue to enjoy our services forever with the free plan unless you reached the message limit."
      : "You have exceeded your chatbot limit and cannot create more chatbots.";
    text = "Upgrade to create more chatbots and expand your capabilities.";
    conditions = [botListPage, dashboardPage].includes(path);
  } else if (isOrgCountExceeded) {
    title = "Organization Limit Exceeded";
    description =
      "You have exceeded your organization limit and cannot create more organizations.";
    text =
      "Upgrade now to add more organizations and expand your capabilities.";
    conditions = [botListPage, dashboardPage].includes(path);
  }

  if (path === membersPage && isMemberCountExceeded) {
    title = "Team Member Limit Exceeded";
    description =
      "You have exceeded your team member limit and cannot add more team members.";
    text = "Upgrade now to include more team members in your projects.";
    conditions =
      [botListPage, dashboardPage, membersPage].includes(path) &&
      !!canCreateMember;
  }

  const data = {
    conditions,
    title,
    description,
    text,
    buttons: (
      <>
        <Button variant="outline" asChild>
          <Link href="/pricing#compare">Compare Plans</Link>
        </Button>
        <Button asChild>
          <Link href="/account/plans">Upgrade Now</Link>
        </Button>
      </>
    ),
  };

  if (!canUpdate || !open || !data.conditions) return null;

  return (
    <div className="fixed z-[99999999999999] grid sm:max-w-sm overflow-hidden border bg-background shadow-lg rounded-lg right-4 sm:right-auto left-4 bottom-4">
      <div className="flex gap-4 items-center justify-between border-b bg-[#f6f5f4] px-3 sm:px-6 py-3">
        <div className="">
          <p className="text-lg text-pretty font-semibold leading-[normal] tracking-tight">
            {data.title}
          </p>
        </div>
        <Button
          onClick={() => {
            setOpen(false);
            setCookie("exceededDialog", "closed");
          }}
          className="shrink-0 mr-[-0.7rem]"
          variant="link"
          size="icon-sm"
        >
          <XIcon className="size-3.5" />
        </Button>
      </div>
      <div>
        <div className="p-3 flex flex-col gap-4 sm:py-4 sm:px-6">
          <p className="text-sm text-gray-700 text-justify">
            {data.description}
          </p>
          <div className="grid grid-cols-2 gap-4">{data.buttons}</div>
        </div>
      </div>
    </div>
  );
}
