"use client";

import { Command, useCommandState } from "cmdk";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { POPULAR_ARTICLES } from "@/constants";
import { allHelp } from "contentlayer/generated";
import { useRouter } from "next/navigation";
import Highlighter from "react-highlight-words";
import { MagicWandIcon } from "@radix-ui/react-icons";
import { ArrowRightIcon, ChevronRightIcon, ExpandIcon } from "lucide-react";
import Modal from "./modal";
import { Dialog, DialogContent, DialogTitle } from "../ui/dialog";

import useCMDKStore from "@/store/useCMDK";
import { AlertDialogTitle } from "../ui/alert-dialog";

function CMDKHelper({
  showCMDK,
  setShowCMDK,
}: {
  showCMDK: boolean;
  setShowCMDK: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Dialog open={showCMDK} onOpenChange={setShowCMDK}>
      <DialogContent
        hideClose
        className="p-0 rounded-lg max-h-[420px] min-h-[250px]"
      >
        <DialogTitle className="hidden "></DialogTitle>
        <Command
          label="CMDK"
          loop
          filter={(value, search) => {
            if (value.toLowerCase().includes(search.toLowerCase())) return 1;
            return 0;
          }}
        >
          <Command.Input
            autoFocus
            placeholder="Search articles, guides, and more..."
            className="w-full border-none p-4 font-normal placeholder-gray-400 focus:outline-none focus:ring-0 rounded-lg"
          />
          <Command.List className="h-[50vh] max-h-[360px] min-h-[250px] overflow-scroll border-t border-gray-200 p-2 transition-all scrollbar-hide sm:h-[calc(var(--cmdk-list-height)+10rem)]">
            <Command.Empty className="flex cursor-not-allowed items-center space-x-2 rounded-md bg-gray-100 px-4 py-2 text-sm text-gray-600">
              <MagicWandIcon className="h-4 w-4 text-gray-400" />
              <div className="flex flex-col space-y-1">
                <p className="text-sm font-medium text-purple-600">
                  Ask AI (Coming soon)
                </p>
                <p className="text-xs text-gray-400">
                  Use our AI to find answers to your questions
                </p>
              </div>
            </Command.Empty>
            <CommandResults setShowCMDK={setShowCMDK} />
          </Command.List>
        </Command>
      </DialogContent>
    </Dialog>
  );
}

const CommandResults = ({
  setShowCMDK,
}: {
  setShowCMDK: Dispatch<SetStateAction<boolean>>;
}) => {
  const router = useRouter();
  const popularArticles = POPULAR_ARTICLES.map((article) => {
    return allHelp.find((post) => post.slug === article.path)!;
  });

  const allItems = [
    ...allHelp,
    // get all table of contents headings too
    ...allHelp.flatMap((post) =>
      post.tableOfContents.map((toc: { title: string; slug: string }) => ({
        slug: `${post.slug}#${toc.slug}`,
        title: toc.title,
        summary: `In: "${post.title}"`,
      })),
    ),
  ];

  const search = useCommandState((state) => state.search ?? "");

  return (search.length === 0 ? popularArticles : allItems).map((item) => (
    <Command.Item
      key={item?.slug}
      value={item?.title}
      onSelect={() => {
        router.push(`${item?.slug}`);
        setShowCMDK(false);
      }}
      className="group flex cursor-pointer items-center justify-between space-x-2 rounded-md px-4 py-2 hover:bg-gray-100 active:bg-gray-200 aria-selected:bg-gray-100"
    >
      <div className="flex flex-col space-y-1">
        <Highlighter
          highlightClassName="underline bg-transparent text-purple-500"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={item?.title}
          className="text-sm font-medium text-gray-600 group-aria-selected:text-purple-600 sm:group-hover:text-purple-600"
        />
        <Highlighter
          highlightClassName="underline bg-transparent text-purple-500"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={item?.description}
          className="line-clamp-1 text-xs text-gray-400"
        />
      </div>
      <div className="group relative flex items-center">
        <ChevronRightIcon className="absolute transition-all group-hover:translate-x-1 group-hover:opacity-0 -ml-4 h-4 w-4 text-muted-foreground group-hover:text-purple-600" />
        <ArrowRightIcon className="-ml-4 h-4 w-4 text-muted-foreground group-hover:text-purple-600 absolute opacity-0 transition-all group-hover:translate-x-1 group-hover:opacity-100" />
      </div>
    </Command.Item>
  ));
};

export default function useCMDK() {
  const { open, onOpenChange } = useCMDKStore();

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onOpenChange(!open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const CMDK = useCallback(() => {
    return <CMDKHelper showCMDK={open} setShowCMDK={onOpenChange} />;
  }, [open, onOpenChange]);

  return useMemo(
    () => ({ open, onOpenChange, CMDK }),
    [open, onOpenChange, CMDK],
  );
}
