"use client";
import useCMDK from "@/components/shared/cmdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";
import { Dispatch, SetStateAction, createContext } from "react";

export const AppContext = createContext<{
  setShowCMDK: Dispatch<SetStateAction<boolean>>;
}>({
  setShowCMDK: () => {},
});

export function QueryProvider({ children }: { children: ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

export default function Providers() {
  const { CMDK } = useCMDK();

  useCMDK();

  return (
    <>
      <CMDK />
    </>
  );
}
