import { create } from "zustand";

type OnOpenChangeFunction = (open: boolean) => boolean;

interface CMDKState {
  open: boolean;
  onOpenChange: (open: boolean | OnOpenChangeFunction) => void;
}

const useCMDKStore = create<CMDKState>((set) => ({
  open: false,

  onOpenChange: (open) => {
    set((state) => ({
      open: typeof open === "function" ? open(state.open) : open,
    }));
  },
}));

export default useCMDKStore;
